<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        <h3>Producten</h3>
        <DataTable :value="products" :lazy="true" :paginator="true" :rows="20" v-model:filters="filters" ref="dt"
                   :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)" filterDisplay="row"
                   class="p-datatable-sm" :autoLayout="true">
          <template v-if="!$root.isDesktop()" #header>
            <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
            <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['name'].value" @input="mobileSearch()" placeholder="Zoek product..."/>
            </span>
            </div>
          </template>
          <Column field="name" header="Naam" ref="name" :sortable="true">
            <template #filter="{filterModel,filterCallback}">
              <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" placeholder="Zoek op naam"/>
            </template>
          </Column>
          <Column field="category" header="Categorie" ref="category" :sortable="true">
            <template #filter="{filterModel,filterCallback}">
              <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" placeholder="Zoek op categorie"/>
            </template>
          </Column>
          <Column field="brand" header="Merk" ref="brand" :sortable="true">
            <template #filter="{filterModel,filterCallback}">
              <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" placeholder="Zoek op merk"/>
            </template>
          </Column>
          <Column field="hasStock" header="Op voorraad" dataType="boolean" ref="hasStock">
            <template #body="{data}">
              <i class="pi" :class="{'true-icon pi-check-circle': data.hasStock, 'false-icon pi-times-circle': !data.hasStock}" style="fontSize: 1.5rem"></i>
            </template>
            <template #filter="{filterModel,filterCallback}">
              <TriStateCheckbox v-model="filterModel.value" @change="filterCallback()" />
            </template>
          </Column>
          <Column header="Bestellijst" headerStyle="width: 160px">
            <template #body="{data}">
              <template v-if="$root.orderLists.length">
                <SplitButton label="Voeg toe" icon="pi pi-plus" :model="orderLists(data)"></SplitButton>
              </template>
              <template v-else>
                <small style="color: #D32F2F;">Maak een bestellijst aan</small>
              </template>
            </template>
          </Column>

          <template #empty>
            Geen producten gevonden
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import Translate from '../mixins/Translate.js'
import ProductService from '../service/ProductService'
import {FilterMatchMode} from 'primevue/api'

export default {
  mixins: [Translate],
  data() {
    return {
      loading: false,
      totalRecords: 0,
      products: null,
      filters: {
        'name': {value: '', matchMode: FilterMatchMode.CONTAINS},
        'category': {value: '', matchMode: FilterMatchMode.CONTAINS},
        'brand': {value: '', matchMode: FilterMatchMode.CONTAINS},
        'hasStock': {value: null, matchMode: FilterMatchMode.EQUALS}
      },
      lazyParams: {}
    }
  },
  productService: null,
  created() {
    this.productService = new ProductService()
  },
  mounted() {
    this.loading = true

    this.lazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
      isTyping: null
    };

    this.loadLazyData();
  },
  watch: {
    filters: {
      handler() {
        this.filtersChanged()
      }
    }
  },
  methods: {
    mobileSearch() {
      this.filtersChanged()
    },
    filtersChanged() {
      if (this.isTyping) {
        clearTimeout(this.isTyping)
      }

      this.isTyping = setTimeout(() => {
        this.lazyParams.page = 1
        this.lazyParams.first = 0
        this.lazyParams.filters = this.filters
        this.loadLazyData()
      }, 300)
    },
    orderLists(product) {
      const items = []

      this.$root.orderLists.forEach(orderList => {
        items.push({
          label: orderList.name,
          icon: 'pi pi-download',
          command: () => {
            this.$root.orderListService.addProductToOrderList({
              orderList,
              product
            }).then(() => {
              this.$toast.add({
                severity: 'success',
                summary: 'Toegevoegd',
                detail: `${product.name} toegevoegd aan ${orderList.name}`,
                life: 5000
              })
            }).catch(() => {
              this.$toast.add({severity: 'error', summary: 'Error', detail: 'Er ging wat mis'})
            })

          }
        })
      })

      return items
    },
    loadLazyData() {
      this.loading = true;

      this.productService.getProducts(
          {lazyEvent: JSON.stringify( this.lazyParams )})
          .then(data => {
                this.products = data.products
                this.totalRecords = data.totalRecords
                this.loading = false
              }
          )
    },
    onPage(event) {
      this.lazyParams = event
      this.loadLazyData()
    },
    onSort(event) {
      this.lazyParams = event
      this.loadLazyData()
    },
    onFilter() {
      this.lazyParams.filters = this.filters;
      this.loadLazyData();
    }
  }
}
</script>

<style lang="scss" scoped>
.true-icon {
  color: #256029;
}

.false-icon {
  color: #c63737;
}
</style>
