import axios from 'axios';

export default class ProductService {

	getProducts(params) {
		return axios.get('/product/list', { params }).then(res => res.data)
    }

	getImportResult() {
		return axios.get(`/admin/product/import-result`).then(res => res.data)
	}

	startImport() {
		return axios.get(`/admin/product/import-start`).then(res => res.data)
	}

	cancelImport() {
		return axios.get(`/admin/product/import-cancel`).then(res => res.data)
	}
}
